import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BannerSlideItemFactory } from '@fca-app/models/banners/banner-slide-item.factory';
import { BannerSlideItemModel } from '@fca-app/models/banners/banner-slide-item.model';
import { BannersService } from '@fca-app/services/banners.service';
import { EFrontType } from '@fca-app/shared/enums/front-type.enum';
import { ELocale } from '@fca-app/shared/enums/locale.enum';
import { UntilDestroy, untilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-dashboard-manage-banners',
    templateUrl: './manage-banners.component.html',
    styleUrls: ['./manage-banners.component.scss'],
})
export class ManageBannersComponent implements OnInit {
    selectedFile: File | null = null;
    banners: BannerSlideItemModel[] = [];
    form: FormGroup;
    frontType = EFrontType;
    locales = ELocale;
    @ViewChild('carousel') carousel: NzCarouselComponent;
    activeSlide: BannerSlideItemModel | null = null;

    constructor(
        private readonly bannersService: BannersService,
        private readonly nzNotificationService: NzNotificationService
    ) {
        this.bannersService
            .getBanners()
            .pipe(untilDestroy(this), take(1))
            .subscribe(banners => {
                this.banners = banners;
                this.loadSlideData(this.banners[0]);
            });
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            id: new FormControl(null),
            image: new FormControl('', [Validators.required]),
            needToOpenNewTab: new FormControl(false, [Validators.required]),
            destinationUrl: new FormControl(null, []),
            title: new FormControl('', [Validators.required]),
            content: new FormControl('', [Validators.required]),
            btnText: new FormControl('', [Validators.maxLength(12), Validators.minLength(0)]),
            position: new FormControl(0, [Validators.min(0)]),
            platform: new FormControl('', [Validators.required]),
            locale: new FormControl('', [Validators.required]),
        });
    }

    get hasChanges(): boolean {
        return JSON.stringify(this.form.value) !== JSON.stringify(this.activeSlide);
    }

    loadSlideData(model: BannerSlideItemModel): void {
        this.form.patchValue({
            id: model.id,
            image: model.image,
            needToOpenNewTab: model.needToOpenNewTab,
            destinationUrl: model.destinationUrl,
            title: model.title,
            content: model.content,
            btnText: model.btnText,
            position: model.position,
            platform: model.platform,
            locale: model.locale,
        });
        this.activeSlide = model;
    }

    onFileChange(ev: Event): void {
        const file = (ev.target as HTMLInputElement).files![0];
        const reader = new FileReader();

        reader.onload = () => {
            this.selectedFile = file;
            const activeBanner = this.banners[this.carousel.activeIndex];
            activeBanner.image = reader.result as string;
            this.form.patchValue({ image: file.name });
        };
        reader.readAsDataURL(file);
    }

    onSlideChange(slide: number): void {
        this.loadSlideData(this.banners[slide]);
    }

    onDeleteBanner(ev: Event): void {
        ev.preventDefault();
        if (this.activeSlide?.id) {
            this.bannersService
                .deleteBanner(this.activeSlide.id)
                .pipe(untilDestroy(this), take(1))
                .subscribe(() => {
                    this.banners = this.banners.filter(b => b.id !== this.activeSlide!.id);

                    if (!this.banners.length) {
                        this.activeSlide = null;
                    }
                });
        }
    }

    get deleteAvailable(): boolean {
        return this.activeSlide?.id !== null;
    }

    onAddNewBanner(ev: Event): void {
        ev.preventDefault();
        this.banners.unshift(new BannerSlideItemFactory().getEmpty());
        this.onSlideChange(0);
    }
    submitForm(): void {
        if (this.form.valid) {
            this.bannersService
                .saveBanner(this.form.value, this.selectedFile)
                .pipe(untilDestroy(this), take(1))
                .subscribe(model => {
                    this.nzNotificationService
                        .success('Success', '', { nzDuration: 1500 })
                        .onClick.pipe(take(1), untilDestroy(this))
                        .subscribe();
                });
        }
    }
}
