import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreConfig, StoreModule } from '@ngrx/store';
import { fcaFeatureReducer } from './reducers';
import { FcaState } from './state';
import { storageMetaReducer } from './storage.metareducer';
import { FcaStoreService } from './store.service';

export const FEATURE_CONFIG_TOKEN = new InjectionToken<StoreConfig<FcaState>>('Checkout state config');

export function getConfig(): StoreConfig<FcaState | undefined> {
    return {
        metaReducers: [
            ...(window.localStorage
                ? [
                      storageMetaReducer([
                          {
                              storageService: window.localStorage,
                              storageKey: '__fca_app_storage__',
                              saveKeys: ['authData', 'viewArenasPage', 'viewFightersPage'],
                          },
                      ]),
                  ]
                : []),
        ],
    };
}

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forRoot([]),
        StoreModule.forFeature('fca', fcaFeatureReducer, FEATURE_CONFIG_TOKEN),
    ],
    providers: [
        FcaStoreService,
        {
            provide: FEATURE_CONFIG_TOKEN,
            useFactory: getConfig,
        },
    ],
})
export class FcaStoreModule {}
